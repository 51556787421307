import { bindable, computedFrom } from 'aurelia-framework';
import { Security } from 'common/security';
import { I18n } from 'common/i18n';
import { Imports } from 'services/imports';
import { Carriers } from 'services/carriers';
import { Notifier, ContentFullHeight } from 'common/ui';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';

export class ImportFile {
    static inject = [Element, Security, I18n, Imports, Carriers, Notifier, NewInstance.of(ValidationController)];
    _i18n;
    _imports;
    _carriers;
    _notifier;
    _validationController;

    @bindable type;
    @bindable defineFile;

    step = 'upload';
    _fileDefinitionId;
    fileDefinitionName;
    fileDefinitionSource;
    fileDefinitionSupplier;
    columns;
    _fileUploadPath;
    typeFilters = [{ value: '', keys: ['name'] }];
    dateFormats = ['MM/dd/yyyy', 'MM/dd/yy', 'M/d/yyyy', 'M/d/yy', 'yyyy-MM-dd', 'yyyy-M-d', 'dd/MM/yyyy', 'd/M/yyyy', 'yyyyMMdd', 'yyyy-MM-ddTHH:mm:ss.000Z'];
    importTypeName;
    carriers = [];
    carrierId;

    policyUpdateOnly = false;
    universalLifeAnnualizedPremiumIsTargetPremium = false;
    setCanceledDateWhenChargedBack = true;
    useExistingSplitsOnUpdate = false;
    updateByPolicyNumberOnly = false;
    noWritingAgentNumber = false;
    ignoreDuplicatePolicyNumbers = false;
    postIssueUpdateOnly = false;
    doNotUpdateStatus = false;

    constructor(element, security, i18n, imports, carriers, notifier, validationController) {
        this._element = element;
        this._i18n = i18n;
        this._imports = imports;
        this._carriers = carriers;
        this._notifier = notifier;
        this.agentId = security.authenticatedMemberId;
		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('fileDefinitionName').required().when(x => x.step === 'define-import')
            .ensure('fileDefinitionSource').required().when(x => x.step === 'define-import' && x.type === 'lead')
            .ensure('fileDefinitionSupplier').required().when(x => x.step === 'define-import' && x.type === 'lead')
            .ensure('carrierId').required().when(x => x.step === 'define-import' && x.type === 'policy')
            .on(this);
    }

    attached() {
        this.cancel();
        this._fileUploadPath = undefined;

        this._contentFullHeight = new ContentFullHeight();
        this._contentFullHeight.initialize(this.fullHeightEl);
        this.importTypeName = this._i18n.tr(`import-type-${this.type}-title`);
        this._loadCarriers();
    }

    detached() {
        this._contentFullHeight.dispose();
    }

    @computedFrom('type')
    get saveButton() {
        return this.type === 'policy' ? 'save' : 'save-and-import-file';
    }

    async _loadCarriers() {
        this.carriers = await this._carriers.all();
    }

    cancel() {
        this._clear();
        this._element.dispatchEvent(new CustomEvent('cancelled', { bubbles: true, detail: {} }));
    }

    _clear() {
        this.step = 'upload';
        this.columns = [];
        this.columnDataTypes = [];
        this.propertyBagDataTypes = [];
        this._fileUploadPath = undefined;
        this.column = undefined;
        this.columnIndex = undefined;
        this.importErrors = undefined;
        this.importCount = undefined;
        this.mappingColumn = false;
        this.customField = undefined;
        this.fileDefinitionName = undefined;
        this.fileDefinitionSource = undefined;
        this.fileDefinitionSupplier = undefined;
    }

    defineFileChanged() {
        if (!this.defineFile) return;
        this.onFileDefinitionNotFound(this.defineFile);
    }

    onFileDefinitionNotFound(data) {
        this.step = 'define-import';
        this.columns = data.file.columns;
        this.columnDataTypes = data.file.columnDataTypes;
        this.propertyBagDataTypes = data.file.propertyBagDataTypes;
        this._fileUploadPath = data.file.filePath;
    }

    startMappingColumn(column, index) {
        this.column = column;
        this.typeFilters[0].value = column.columnHeading ? column.columnHeading.replaceAll('_', ' ') : '';
        this.columnIndex = index;
        this.mappingColumn = true;
    }

    ignoreColumn(column, index) {
        this.startMappingColumn(column, index);
        this.selectMapping({ type: 'misc', name: '-' });
    }

    addCustomField() {
        if (!this.column) return;
        this.customField = {
            dataType: 'custom',
            isDecimal: false,
            name: this.column.columnHeading,
            nameKey: '',
            importKey: '',
            importType: 'Bag',
            valueDataType: undefined,
        };
        if (this.column.columnHeading.toLowerCase().indexOf('date') >= 0) this.customField.valueDataType = 'Date';
        else if (this.column.columnHeading.toLowerCase().indexOf('phone') >= 0) this.customField.valueDataType = 'Phone';
        else if (this.column.columnHeading.toLowerCase().indexOf('premium') >= 0) this.customField.valueDataType = 'Money';
        else this.customField.valueDataType = 'String';
    }

    selectCustomFieldMapping() {
        this.selectMapping(this.customField);
    }

    selectMapping(dataType) {
        if (dataType.dataType === 'custom') {
            if (!dataType.name) {
		        this._notifier.error('enter-custom-column-name');
                return;
            }
            const clonedDataType = JSON.parse(JSON.stringify(dataType));
            clonedDataType.importKey = dataType.name;
            clonedDataType.name = dataType.name;
            clonedDataType.dataType = dataType.valueDataType;
            this.column.mapped = clonedDataType;
        } else {
            this.column.mapped = dataType;
        }
        this.customField = undefined;
        this.typeFilters[0].value = '';
        const allAreMapped = this.columns.find(x => !x.mapped) === undefined;
        if (allAreMapped) {
            // do not select another one
            this.cancelColumnMapping();
            return;
        }
        if (!isNaN(this.columnIndex) && this.columns.length > this.columnIndex + 1) {
            const startMappingIndex = this.columnIndex + 1;
            if (this.columns[startMappingIndex].mapped) {
                this.cancelColumnMapping();
            } else {
                this.startMappingColumn(this.columns[startMappingIndex], startMappingIndex);
            }
        } else {
            this.cancelColumnMapping();
        }
    }

    cancelColumnMapping() {
        this.column = undefined;
        this.columnIndex = undefined;
        this.mappingColumn = false;
        this.customField = undefined;
    }

	async saveDefinition() {
	    if (this.processing) return;
        try {
            const v = await this.validationController.validate();
            console.log(v);
            if (!v.valid) return;

            const columnNotMapped = this.columns.find(x => !x.mapped);
            if (columnNotMapped) {
		        this._notifier.error('save-file-definition-map-columns-error');
                return;
            }

            this.processing = true;
            const payload = {
                id: this._fileDefinitionId,
                filePath: this._fileUploadPath,
                name: this.fileDefinitionName,
                importType: this.type,
                source: this.fileDefinitionSource,
                supplier: this.fileDefinitionSupplier,
                columns: this.columns,
                columnDataTypes: this.columnDataTypes,
                propertyBagDataTypes: this.propertyBagDataTypes,
                carrierId: this.carrierId,
                policyUpdateOnly: this.policyUpdateOnly,
                universalLifeAnnualizedPremiumIsTargetPremium: this.universalLifeAnnualizedPremiumIsTargetPremium,
                setCanceledDateWhenChargedBack: this.setCanceledDateWhenChargedBack,
                useExistingSplitsOnUpdate: this.useExistingSplitsOnUpdate,
                updateByPolicyNumberOnly: this.updateByPolicyNumberOnly,
                noWritingAgentNumber: this.noWritingAgentNumber,
                ignoreDuplicatePolicyNumbers: this.ignoreDuplicatePolicyNumbers,
                postIssueUpdateOnly: this.postIssueUpdateOnly,
                doNotUpdateStatus: this.doNotUpdateStatus,
            };
	        const result = await this._imports.saveDefinition(payload);
            if (result.succeeded && result.model && !result.model.errors.length) {
                this.onFileUploadSuccess(result.model);
                return;
            }
            // An error occurred
            this._notifier.error(result.message);
	    } catch (err) {
            console.log(err);
        } finally {
            this.processing = false;
        }
	}

    onFileUploadSuccess(data) {
        try {
            this._element.dispatchEvent(new CustomEvent('definition-saved', { bubbles: true, detail: {} }));

            this._clear();
            this._i18n.reloadTranslations();

            switch (this.type) {
                case 'policy':
                    this.step = 'upload';
                    break;
                case 'lead':
                    this.step = 'results';
                    this.importErrors = data.errors;
                    this.importCount = data.leads.length;
                    break;
            }
        } catch (err) {
            console.error(err);
        }
    }

    startNewImport() {
        this._clear();
    }
}